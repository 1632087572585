html {
  scroll-behavior: smooth;
}

.header-image{
  background: url('assets/images/header_image.png');
  background-position:center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 420px;
}

.section_situacion_actual_bg{
  background: url('assets/images/section_bg_actual_stituation.svg');
  background-position: center;
}

@media (max-width: 1920px){
  
  .header-image{
    background-size: 1440px;
    height: 80vh;
  }

  .section_situacion_actual_bg{
    background: url('assets/images/section_bg_actual_stituation_1920.svg');
    background-position: center;
  }
}

@media (max-width: 1440px){

  .header-image{
    background-size: 1440px 721px;
    height: 721px;
  }

  .section_situacion_actual_bg{
    background: url('assets/images/section_bg_actual_stituation_1440.svg');
    background-position: center;
  }
}

@media (max-width: 1280px){

  .header-image{
    background-size: 1280px;
    height: 74vh;
  }

  .section_situacion_actual_bg{
    background: url('assets/images/section_bg_actual_stituation_1280.svg');
    background-position: center;
  }
}

@media (max-width: 1024px){
  .header-image{
    background-size: 1024px 518px;
    height: 518px
  }

  .section_situacion_actual_bg{
    background: url('assets/images/section_bg_actual_stituation_1024.svg');
    background-position: center;
  }
}

@media (max-width: 390px){
  .section_situacion_actual_bg{
    background: url('assets/images/section_bg_actual_stituation_390.svg');
    background-position: center;
  }
}



.differences_bg{
  background: url('assets/images/differences_image.png');
  background-position: center center;
  background-repeat: no-repeat;
}

.instalations_bg{
  background: url(assets/images/instalaciones.png);
  background-position: center center;
  background-repeat: no-repeat;
}

select.select_box{
  background-image: url(assets/images/select_button_arrow.svg);
  background-repeat: no-repeat;
  background-position: right center;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
}
select::-ms-expand {
  display: none; /*Evita que se muestre la flecha por defecto en versiones de IE*/
}

.shadow_text_illustration{
  text-shadow: 0px 3px 4px rgba(131, 91, 18, 0.80);
}

.horneandosuenos-bg{
  background: url('assets/images/horneando_suenos.png');
  background-position: center;
}
